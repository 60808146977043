export function multinomialCoefficients(n, d) {
  //assert((typeof n) === "number" || n instanceof Number, "n must be a number")
  //assert((typeof d) === "number" || d instanceof Number, "d must be a number")
  //assert((n%1)===0 && (d%1)===0, "n and d must be integers")
  //assert(n>=0, "n must be non-negative")
  //assert(d>0, "d must be positive")

  function multUpdate(i1, i2) { // Increase count for i1, decrease count for i2, and update multinomial coefficient.
    var m1 = counthist[i1]
    var m2 = counthist[i2]
    coef = (coef*m2/(m1+1)) |0 // New coefficient (always remains an integer)
    counthist[i1] += 1
    counthist[i2] -= 1
  }

  // Gather multiplicities for all (non-decreasing) sequences of counts in lexicographic order
  // That is, we imagine counthist to be a run-length encoded (non-decreasing) sequence of numbers in the range [0,d), and we iterate over those in lexicographic order.
  var counthist = new Int32Array(d), allCounts = [], coef = 1, coefs = [coef]
  counthist[0] = n // Initially we have n zeroes
  allCounts.push([...counthist])
  while(counthist[d-1]<n) { // Loop while something can be increased
    for(var i=d-1; i-->0;) { // Find right-most assignment that can be increased (skipping d-1!)
      if (counthist[i]>0) break
    }
    multUpdate(i+1, i)
    if (i+1<d-1) {
      // Make sure we have the lowest non-decreasing sequence with this prefix by changing all the d-1's into i+1's.
      while(counthist[d-1]>0) {
        multUpdate(i+1, d-1)
      }
    }
    allCounts.push(Array.from(counthist))
    coefs.push(coef) // Push onto list with multiplicities
  }

  return {counts: allCounts, coefs: coefs}
}
