import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const stats = ['ac', 'con', 'str', 'dex', 'wis', 'int', 'cha'];
const group_by_key = 'cr';

function fetchData() {
	return axios.get('/monster/analysis').then(x => x?.data ?? []);
}

function transformData(api_response) {
	let refdata = [];
	if(api_response === undefined || api_response.length === 0)
		return [];
	for(let stat_name of stats) {
		for(let api_groups of api_response) {
			let x = parseFloat(api_groups[group_by_key]);
			if(x === undefined)
				continue;
			let s_group = api_groups[stat_name];
			if(s_group === undefined)
				continue;
			for(let bin_group of s_group) {
				refdata.push(...Array(bin_group.c).fill(bin_group.v).map(v => {
					let r = {x: x};
					r[stat_name] = v;
					return r;
				}));
			}
		}
	}
	return refdata;
}

function first_third_quartile_from_scatter(xy_data) {
	xy_data.sort(function(a, b){return a - b});
	let len = xy_data.length;
	let q1 = Math.floor((len / 4));
	let q2 = Math.floor((len / 2));
	let q3 = Math.floor((len * 3 / 4));
	return { 
		q1: xy_data[q1],
		q2: xy_data[q2],
		q3: xy_data[q3],
	}
}

const average = array => array.reduce((a, b) => a + b) / array.length;

const NumberMask = (value) => (
	<span>{value.toFixed(2)}</span>
);

export default function MonsterStats() {
	const { data, isLoading, error } = useQuery({queryKey: ["monsters_stats"], queryFn: fetchData, staleTime: 10 * 1000});
	if(isLoading) return (<div>Loading</div>);
	if(error) return (<div>Error: {error.message}</div>);

	let xydata_allstats = transformData(data);

	if(xydata_allstats === undefined)
		return;

	const line_chart_data = [];
	let groups_x = Object.groupBy(xydata_allstats, ({x}) => x);
	for(let k of Object.keys(groups_x)) {
		let item = {x: parseFloat(k)};
		for(let s of stats) {
			var s_values = groups_x[k].filter(i => i[s] !== undefined).map(x => x[s]);
			if(s_values.length > 0)
				item[s] = average(s_values);
		}
		line_chart_data.push(item);
	}
	line_chart_data.sort((a, b) => a.x - b.x);

	const dist_per_stat_chart = {};
	for(let s of stats) {
		let my_points = xydata_allstats.filter(i => i[s] !== undefined)
		let groups_x = Object.groupBy(my_points, ({x}) => x);
		const stat_list = [];
		for(let k of Object.keys(groups_x)) {
			let data = groups_x[k].map(x => x[s]);
			const {q1, q2, q3} = first_third_quartile_from_scatter(data);
			stat_list.push({x: parseFloat(k), q1, q2, q3});
		}
		stat_list.sort((a, b) => a.x - b.x);
		dist_per_stat_chart[s] = stat_list;
	}

	const stroke_colors = {
		hp: 'red',
		con: 'orange',
		str: 'gray',
		dex: 'yellow',
		wis: 'green',
		int: 'lightblue',
		cha: 'purple',
	};

	return (
		<>
			<div>
				<div><Link to="/monster" className='primary'>Monsters</Link> </div>
				<h2>Average Stat / CR</h2>
				<ResponsiveContainer width={'99%'} height={300}>
					<LineChart
					  height={300}
					  data={line_chart_data}
					>
						<XAxis dataKey="x" />
						<YAxis width={20} />
						<Tooltip contentStyle={{backgroundColor: 'black'}} formatter={NumberMask} />
						<Legend />
						{stats.map(s => ((
							<Line key={s} type="monotone" dataKey={s} dot={false} stroke={stroke_colors[s]}/>
						)))}
					</LineChart>
			  </ResponsiveContainer>
			</div>
			{stats.map(s => ((
				<div key={s}>
					<h2>Distribution of {s} / CR</h2>
					<ResponsiveContainer width={'99%'} height={300}>
						<LineChart
						  height={300}
						  data={dist_per_stat_chart[s]}
						>
							<XAxis dataKey="x" />
							<YAxis width={20} />
							<Tooltip contentStyle={{backgroundColor: 'black'}} formatter={NumberMask} />
							<Legend />
							<Line type="monotone" dataKey="q1" dot={false} stroke={stroke_colors[s]}/>
							<Line type="monotone" dataKey="q2" dot={false} stroke={stroke_colors[s]}/>
							<Line type="monotone" dataKey="q3" dot={false} stroke={stroke_colors[s]}/>
						</LineChart>
				  </ResponsiveContainer>
				</div>
			)))}
		</>
	);
}
