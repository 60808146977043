import '../styles/Monster.sass';
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import FuzzySearch from 'fuzzy-search';
import ReactPaginate from 'react-paginate';
import { useModal } from '../UseModal.js';

function fetchData() {
	return axios.get('/monster').then(x => x?.data ?? []);
}

function trimEllip(str, length) {
	if(str === undefined)
		return undefined;
	return str.length > length ? str.substring(0, length) + "..." : str;
}
function bind(min, med, max) { return Math.max(min, Math.min(med,max)); }

function MonsterDialog(monster) {
	if(monster === undefined)
		return (<span>no monster here.</span>);
	return (
		<>
		<h1><a href={monster.link} target="_blank">{monster.name}</a></h1>
		<div><label>CR</label><span>{monster.cr}</span></div>
		<div><label>Source book</label><span>{monster.sourceBook}</span></div>
		<div dangerouslySetInnerHTML={{ __html: monster.attackDescription }} />
		</>
	);
}

export default function Monsters() {
	const [searchText, setSearchText] = useState('');
	const [crFilter, setCrFilter] = useState(-1);
	const [monsterDialogElement, openMonsterDialog] = useModal(MonsterDialog);
	const [paging, setPaging] = useState({ perPage: 10, pageNumber: 1 });
	const { data, isLoading, error } = useQuery({queryKey: ["allmonsters"], queryFn: fetchData, staleTime: 10 * 1000});
	if(isLoading) return (<div>Loading</div>);
	if(error) return (<div>Error: {error.message}</div>);

	let monsters = data;
	let allCr = monsters.map(x => x.cr);
	allCr = [...new Set(allCr)];
	allCr.sort((a, b) => a - b);

	if(crFilter != -1)
		monsters = monsters.filter(x => x.cr == crFilter);
	if(searchText !== undefined && searchText !== '') {
		const splits = searchText.split('and').flatMap(x => x.split('&'));
		for(let s of splits) {
			s = s.trim();
			if(s === '')
				continue;
			const searcher = new FuzzySearch(monsters, ['name', 'sourceBook'], {
				caseSensitive: false,
			});
			monsters = searcher.search(s);
		}
	}
	const maxPageNumber = Math.ceil(Math.max(1, monsters.length) / paging.perPage);
	monsters = monsters.slice(bind(0, (paging.perPage * (paging.pageNumber - 1)), monsters.length - 2),
		bind(1, (paging.perPage * paging.pageNumber), monsters.length - 1));
	return (
	<>
		{monsterDialogElement}
		<div><Link to="/monster/stats" className='primary'>Monster Stats</Link> </div>
		<br/>
		<input type="search" spellCheck="false" placeholder="Search" value={searchText}
		onChange={e => {setPaging({pageNumber: 1, perPage: paging.perPage}); setSearchText(e.target.value.toLowerCase())}}/>

		<span> CR: </span>
		<select onChange={e => setCrFilter(e.target.value)}>
			<option value={-1}>-</option>
			{allCr.map((v, i) => <option key={i} value={v}>{v}</option>)}
		</select>

		<table style={{width: '100%'}}>
			<thead>
				<tr>
					<th scope="col">Name</th>
					<th scope="col">CR</th>
					<th scope="col">Source</th>
				</tr>
			</thead>
			<tbody>
				{monsters.map((item, i) => (
				<tr key={i}>
					<td><button onMouseDown={() => openMonsterDialog(item)} className="primary">{item.name}</button></td>
					<td>{item.cr}</td>
					<td>{trimEllip(item.sourceBook, 30)}</td>
				</tr>
				))}
			</tbody>
		</table>
		<div className='center_contents'>
			<ReactPaginate
				breakLabel="..."
				nextLabel="next >"
				onPageChange={(e) => setPaging({...paging, pageNumber: e.selected + 1})}
				pageRangeDisplayed={5}
				pageCount={maxPageNumber}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
			/>
		</div>

		<div>
			<span>Per Page:</span>
			<select onChange={e => setPaging({perPage: e.target.value, pageNumber: paging.pageNumber})}>
				{[10, 25, 50].map(i => <option key={i} value={i}>{i}</option>)}
			</select>
		</div>
	</>
	);
}
