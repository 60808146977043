// thanks to => https://stackoverflow.com/questions/2276021/evaluating-a-string-as-a-mathematical-expression-in-javascript/75355272#75355272

// WTF!
	// parseFloat('-0') => -0 vs parseFloat(-0) => 0
// -0 === 0 => true vs Object.is(-0, 0) => false
const minus0Hack = (value) => (Object.is(value, -0) ? '-0' : value);

export const operators = {
	'+': {
		func: (x, y) => (state) => !state._add ? (x(state) + y(state)) : state._add(x(state), y(state)),
		precedence: 1,
		associativity: 'left',
		arity: 2
	},
	'-': {
		func: (x, y) => (state) => !state._minus ? (x(state) - y(state)) : state._minus(x(state), y(state)),
		precedence: 1,
		associativity: 'left',
		arity: 2
	},
	'*': {
		func: (x, y) => (state) => !state._multiply ? (x(state) * y(state)) : state._multiply(x(state), y(state)),
		precedence: 2,
		associativity: 'left',
		arity: 2
	},
	'/': {
		func: (x, y) => (state) => !state._divide ? (x(state) / y(state)) : state._divide(x(state), y(state)),
		precedence: 2,
		associativity: 'left',
		arity: 2
	},
	'%': {
		func: (x, y) => () => x() % y(),
		precedence: 2,
		associativity: 'left',
		arity: 2
	},
	'^': {
		func: (x, y) => () => Math.pow(x(), y()),
		precedence: 3,
		associativity: 'right',
		arity: 2
	}
};
export const operatorsKeys = Object.keys(operators);
export const functions = {
	min: { func: (x, y) => state => Math.min(x(state), y(state)), arity: 2 },
	max: { func: (x, y) => state => Math.max(x(state), y(state)), arity: 2 },
	floor: { func: (x) => state => Math.floor(x(state)), arity: 1 },
	ceil: { func: (x) => state => Math.ceil(x(state)), arity: 1 },
};
export const functionsKeys = Object.keys(functions);
const top = (stack) => stack[stack.length - 1];

// Converts infix to postfix notation (reverse polish notation). Example: ['1', '+', '2'] => ['1', '2', '+']
// https://en.wikipedia.org/wiki/Shunting_yard_algorithm
export function shuntingYard(tokens) {
	const output = new Array();
	const operatorStack = new Array();
	for (const token of tokens) {
		if (functions[token] !== undefined)
			operatorStack.push(token);
		else if (token === ',') {
			while (operatorStack.length > 0 && top(operatorStack) !== '(') {
				output.push(operatorStack.pop());
			}
			if (operatorStack.length === 0)
				throw new Error("Misplaced ','");
		}
		else if (operators[token] !== undefined) {
			const o1 = token;
			while (
				operatorStack.length > 0 &&
				top(operatorStack) !== undefined &&
				top(operatorStack) !== '(' &&
					(operators[top(operatorStack)].precedence > operators[o1].precedence ||
						(operators[o1].precedence === operators[top(operatorStack)].precedence &&
							operators[o1].associativity === 'left'))
				) {
				output.push(operatorStack.pop()); // o2
			}
			operatorStack.push(o1);
		}
		else if (token === '(')
			operatorStack.push(token);
		else if (token === ')') {
			while (operatorStack.length > 0 && top(operatorStack) !== '(') {
				output.push(operatorStack.pop());
			}
			if (operatorStack.length > 0 && top(operatorStack) === '(') {
				operatorStack.pop();
			} else {
				throw new Error('Parentheses mismatch');
			}
			if (functions[top(operatorStack)] !== undefined) {
				output.push(operatorStack.pop());
			}
		} else {
			output.push(token);
		}
	}

	// Remaining items
	while (operatorStack.length > 0) {
		const operator = top(operatorStack);
		if (operator === '(') {
			throw new Error('Parentheses mismatch');
		} else {
			output.push(operatorStack.pop());
		}
	}
	return output;
}

export function evalReversePolishNotation(tokens) { // https://en.wikipedia.org/wiki/Reverse_Polish_notation
	const stack = new Array();
	const ops = { ...operators, ...functions };
	for (const token of tokens) {
		const op = ops[token];
		if (op === undefined)
			stack.push(token);
		else {
			const parameters = [];
			for (let i = 0; i < op.arity; i++) {
				parameters.push(stack.pop());
			}
			stack.push(op.func(...parameters.reverse()));
		}
	}
	if (stack.length > 1){
		console.error('Insufficient operators', stack)
		throw new Error('Insufficient operators');
	}
	return stack[0];
}

class Lexer {
	static rules = []
	constructor(source) {
		this.source = source;
	}
	matchRule() {
		for(let r of Lexer.rules) {
			let match = r(this.source);
			if(!match)
				continue;
			this.source = this.source.substring(match.source?.length ?? match.length);
			return match;
		}
		return undefined;
	}
}
Lexer.rules.push(s => (!!s && s[0] === ' ') ? {id: 1, value: ' ', source: ' '} : undefined);
Lexer.rules.push(s => {
	let m = s.match(/^\d+(\.\d+)?/);
	if(!m)
		return undefined;
	let v = minus0Hack(Number(m[0]));
	let vFunc = () => (v ?? 0);
	return {id: 2, value: vFunc, source: m[0]}
});
const single_chars = [...operatorsKeys, '(', ')', ','];
Lexer.rules.push(s => (single_chars.indexOf(s[0]) >= 0) ? {id:3, value:s[0], source:s[0]} : undefined);
Lexer.rules.push(s => {
	let funcName = functionsKeys.find(x => s.startsWith(x));
	if(funcName === undefined)
		return undefined;
	return {id: 4, value: funcName, source: funcName};
});
Lexer.rules.push(s => {
	let m = s.match(/^\{(?<name>[\w\d_]+)}/);
	if(!m)
		return undefined;
	let name = m.groups.name
	let vFunc = (state) => state[name] ?? 0;
	return {id: 5, value: vFunc, source: m[0]}
});

export function tokenize(expression) {
	let stack = [];
	let item = undefined;

	let lexer = new Lexer(expression);
	lexer.source = expression;
	let i = 0;
	while((item = lexer.matchRule()) !== undefined && i++ != 9999) {
		stack.push(item);
	}
	stack = stack.filter(s => s.id !== 1); // remove spaces
	stack = stack.map(s => s.value);
	return stack;
}

export function calculate(expression) {
	if(expression === undefined) {
		console.log('calculate expression is undefined');
		return () => undefined;
	}
	const tokens = tokenize(expression);
	const rpn = shuntingYard(tokens);
	return evalReversePolishNotation(rpn);
}
