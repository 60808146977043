import './App.sass';
import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	NavLink,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Spells from './Pages/Spells';
import SpellDetails from './Pages/SpellDetails';
import Monsters from './Pages/Monsters';
import CharacterPage from './Pages/Character';
import DicePage from './Pages/Dice';
import MonsterStats from './Pages/MonsterStats';
import HomePage from './Pages/Home';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.timeout = 10000; // 10 sec for timeout.

const queryClient = new QueryClient()

function App() {
	const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
	const [ token_set, set_token ] = useState(false);

	const ProtectedRoute = ({ component, ...propsForComponent}) => {
		const Cp = withAuthenticationRequired(component);
		return <Cp {...propsForComponent} />
	}

	useEffect(() => {
		async function getToken() {
			if(!isLoading && !!isAuthenticated) {
				let token = await getAccessTokenSilently({
					audience: new URL(process.env.REACT_APP_API).host,
					cacheMode: 'on',
				});
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
				window.token = token;
				set_token(true);
			}
		}
		getToken();
	});
	if(!isAuthenticated && !isLoading) {
		return (
			<>
				<nav>
					<ul>
						<li><a href="#" onClick={() => loginWithRedirect()}>Log In</a></li>
					</ul>
				</nav>
				<main>
				</main>
			</>
		);
	}
	if (isLoading || !token_set) {
		return <><nav></nav><main>loading...</main></>;
	}
	console.log('Welcome: ', user);
	return (
	<>
	  <Router>
		  <nav>
			<ul>
				<li><NavLink to="/spell">Spell</NavLink></li>
				<li><NavLink to="/monster">Monster</NavLink></li>
				<li><NavLink to="/character">Character</NavLink></li>
				<li><NavLink to="/dice">Dice</NavLink></li>
				<li>
					<a href='#' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
						Log out
					</a>
				</li>
			</ul>
		  </nav>
		  <main>
			  <QueryClientProvider client={queryClient} contextSharing={true}>
				  <Routes>
					  <Route path="/" element={<ProtectedRoute component={HomePage} />} />
					  <Route path="/spell" element={<ProtectedRoute component={Spells} />} />
					  <Route path="/spell/:id" element={<ProtectedRoute component={SpellDetails} />} />
					  <Route path="/monster" element={<ProtectedRoute component={Monsters} />} />
					  <Route path="/monster/stats" element={<ProtectedRoute component={MonsterStats} />} />
					  <Route path="/character" element={<ProtectedRoute component={CharacterPage} />} />
					  <Route path="/dice" element={<ProtectedRoute component={DicePage} />} />
				  </Routes>
			  </QueryClientProvider>
		  </main>
	  </Router>
	  <footer>
	  </footer>
	</>
	);
}

export default App;
