import { useState } from 'react';
import { useDebounce } from "use-debounce";

export function useInput({ type, label, initial_state, list}) {
	if(initial_state === undefined)
		initial_state = '';
	const [value, setValue] = useState(initial_state);
	const [debouncedValue] = useDebounce(type == 'number' ? Number(value) : value, 200);
	const onChange = (e) => {
		const value = e.target.value;
		setValue(value);
	};
	const label_element = (<label hidden={label == ''}>{label}</label>);
	var input = (<span></span>);
	switch(type) {
		default:
		case 'text':
			input = (<input type={type} onChange={onChange} value={value} />);
			break;
		case 'list':
			input = (
				<select onChange={onChange} value={value}>{list.map((opt, i) => (
					<option key={i} value={opt.value}>{opt.text}</option>
					))}
				</select>
			);
			break;
	}
	const input_element = (<div className='input'>{label_element}{input}</div>);
	if(input === 'number')
		debouncedValue = Number(debouncedValue);
	return [debouncedValue, input_element, setValue];
}
