import { useState, useRef } from 'react';
import { useQuery } from "@tanstack/react-query";
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Dice } from '../Dice.js';
import { useDebounce } from "use-debounce";
import axios from 'axios';

async function fetchData() {
	return axios.get('/monster/analysis').then(x => x?.data ?? []);
}

function NumberMask (value) {
	return(<span>{(value*100).toFixed(2)}%</span>);
}

function useInput({ type, label, initial_state}) {
	if(initial_state === undefined)
		initial_state = '';
	const [value, setValue] = useState(initial_state);
	const [debouncedValue] = useDebounce(value, 200);
	const onChange = (e) => {
		const value = e.target.value;
		setValue(value);
	};
	const input = (
		<div className='input'>
		<label hidden={label == ''}>{label}</label><input type={type} onChange={onChange} value={value} />
		</div>
	);
	if(input === 'number')
		debouncedValue = Number(debouncedValue);
	return [debouncedValue, input, setValue];
}

function critChance(dice, critOnValue) {
	if(!critOnValue)
		critOnValue = 20;
	if(!dice)
		return -1;
	let d20s = dice.dice_expressions.filter(d => d.sides === 20);
	if(d20s.length < 1)
		return -1;
	let d20 = d20s[0];
	let histo = d20.CreateHistogram();
	let count = histo.reduce((c, h) => h.count + c, 0);
	let success = histo.reduce((c, h) => c + (h.bin>=critOnValue ? h.count : 0), 0);
	return success / count;
}
function clamp(min, med, max) { return Math.max(min, Math.min(med,max)); }
function toggleDialog(dialogRef) {
	if(dialogRef?.current === null || dialogRef.current === undefined)
		return;
	if(dialogRef.current.hasAttribute('open'))
		dialogRef.current.close();
	else
		dialogRef.current.showModal();
}
function DicePage() {
	const { data: monsters_stats, isLoading, error } = useQuery({queryKey: ["monsters_stats"], queryFn: fetchData, staleTime: 10 * 1000});
	const [variableText, variableElement] = useInput({type: 'text', label: 'Variable:', initial_state: '2'});
	const [toHitDiceText, toHitDiceElement] = useInput({type: 'text', label: 'To Hit:', initial_state: '1d20'});
	const [damageDiceText, damageDiceElement] = useInput({type: 'text', label: 'damage dice:', initial_state: '3d6 + 3'});
	const [saveNameText, setSaveNameText] = useState('');
	const dialogRef = useRef(null);
	if(isLoading) return (<div>Loading</div>);
	if(error) return (<div>Error: {error.message}</div>);

	var dice = new Dice(toHitDiceText);
	dice.variables['variable'] = Number(variableText ?? '0');
	let chanceToCrit = critChance(dice);
	let chanceToHit = 0.0;
	let damageDice = new Dice(damageDiceText);
	let avgDamage = 0;
	if(damageDice?.histogram?.constructor === Array) {
		avgDamage = damageDice.histogram.reduce((ac, c) => ac + (c.bin * c.count), 0) / damageDice.histogram.reduce((ac, c) => ac + c.count, 0);
	}

	let line_chart_data = monsters_stats.map(item => {
		let cr = item.cr;
		let sumMonsters = item.ac.reduce((a, s) => a + s.c, 0);
		let hitChance = item.ac.reduce((acc, h) => acc + (h.c*clamp(0.05,dice.chance_meet_or_beat(h.v),0.95)), 0) / sumMonsters;
		return {cr: cr, damage: hitChance * avgDamage};
	});
	line_chart_data.sort((a, b) => a.cr - b.cr);

	let addStatsToLocalStorage = () => {
		let stored = localStorage.getItem("saved_stats") ?? [];
		stored.push({
			toHitText: toHitDiceText,
			toHitHistogram: dice.histogram,
			damageText: damageDiceText,
			damageHistogram: damageDice.histogram,
			critChance,
		});
		try {
			localStorage.setItem("saved_stats", JSON.stringify(stored));
		} catch {}
	};

		//{variableElement}
	return (
	<>
		{toHitDiceElement}
		{damageDiceElement}
		avg damage: {avgDamage}
		<div hidden={chanceToCrit==-1}><label>chance to hit:</label> {(chanceToHit*100).toFixed(2)} %</div>
		<div><label>chance to crit:</label> {(chanceToCrit*100).toFixed(2)} %</div>
		<ResponsiveContainer width={'99%'} height={300}>
			<BarChart height={300} data={dice.histogram}>
				<CartesianGrid/>
				<XAxis dataKey="bin" />
				<YAxis width={40} />
				<Tooltip contentStyle={{backgroundColor: 'grey'}} formatter={NumberMask} />
				<Legend />
				<Bar dataKey="count"/>
			</BarChart>
		</ResponsiveContainer>
		<ResponsiveContainer width={'99%'} height={300}>
			<LineChart height={300} data={line_chart_data}>
				<XAxis dataKey="cr" />
				<YAxis width={40} />
				<Tooltip contentStyle={{backgroundColor: 'black'}} />
				<Legend />
				<Line type="monotone" dataKey='damage' dot={false} stroke={'lightblue'}/>
			</LineChart>
		</ResponsiveContainer>
		<button className='primary' onMouseDown={() => {setSaveNameText(''); toggleDialog(dialogRef);}}>Save Config</button>
		<dialog ref={dialogRef}>
			<a className="close" onMouseDown={() => toggleDialog(dialogRef)}/>
			<h2>Save name:</h2>
			<div><input type="text" value={saveNameText} onChange={(e) => setSaveNameText(e.target.value)} /></div>
			<br/>
			<button disabled={saveNameText==''} className='secondary' onMouseDown={() => {addStatsToLocalStorage(); toggleDialog(dialogRef);}}>Save to local storage</button>
		</dialog>
	</>
	);
}

export default DicePage;
