import '../styles/Character.sass';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from "@tanstack/react-query";
import { useModal } from '../UseModal.js';
import { useInput } from '../UseInput.js';
import {Dice} from '../Dice.js';
import {calculate} from '../MyMath.js';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useLocalStorage } from "@uidotdev/usehooks";

async function fetchData() {
	return axios.get('/monster/analysis').then(x => x?.data ?? []);
}

function clamp(min, med, max) { return Math.max(min, Math.min(med,max)); }
function statToAbilityBonus(stat) { return Math.floor((stat - 10)/2); }

const defaultStats = {ac: 14, con: 14, str: 13, dex: 16, wis: 8, int: 18, cha: 10};

const crHeaderName = 'CR target';
const fiveEStats = [
	...['ac', 'con', 'str', 'dex', 'wis', 'int', 'cha'].map(s => ({name: s, default: (defaultStats[s] ?? 10).toString(), readonly: false, fill_lower: true})),
	{name: 'hp', default: '10 + ({level} * {conBonus})', readonly: false, fill_lower: true},
	{name: 'prof', default: '2 + floor(({level} - 1)/4)', readonly: true, fill_lower: false},
	{name: 'atks', default: 'floor({level}/5)+1', readonly: false},
	{name: '+toHit', default: '10', readonly: false},
	{name: 'DC', default: 'floor((max({wis}, max({int}, {cha})) - 10) / 2) + {prof} + 8', readonly: false},
	{name: crHeaderName, default: 'floor(({level} - 1) * 30.0 / 20.0)', readonly: false},
].map(fillCalculatedFnForHeader);

function fillCalculatedFnForHeader(header) {
	if(!header || !header.default || header.default === '')
		return header;
	try
	{
		header.default_fn = calculate(header.default);
		//console.log(header.default_fn);
	}
	catch(e){
		console.log(header.default);
		throw e;
	}
	return header;
}

function fillCharacterLevelStats(state, stats, max_level) {
	for(var level = 1; level <= max_level; ++level) {
		if(state[level] === undefined)
			state[level] = {};
		let character = state[level];
		character.level = level;
		let flatData = {level};
		for(var stat of stats) {
			if(character[stat.name]?.user_set === true) {}
			else if(level === 1 || stat.fill_lower !== true) {
				//console.log(stat.default);
				if(!!stat.default && typeof stat.default_fn === 'function')
					character[stat.name] = { value: stat.default_fn(flatData) };
				else
					character[stat.name] = { value: 0 };
			}
			else
				character[stat.name] = { value: state[level - 1][stat.name].value };
			flatData[stat.name] = character[stat.name].value;
		}
	}
	return state;
}

function EditCellModalContent(state, setState, toggle) {
	if(state === undefined) return (<div>no state.</div>);
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			toggle();
		}
	}
	//inputRef?.current?.focus();
	return (
		<>
		
			<h2>Set:</h2>
			<div><input type="text" value={state.value} onKeyDown={handleKeyDown} onChange={(e) => setState({...state, value: e.target.value})}/></div>
			<br/>
			<button disabled={state.value==''} className='secondary' onMouseDown={() => {toggle();}}>Set</button>
		</>
	);
}

function editHeaderModalContent(state, setState, toggle) {
	if(state === undefined) return (<div>no state.</div>);
	let dice = undefined;
	if(!!state.dice_value_string) {
		dice = new Dice(state.dice_value_string);
		dice.variables = {...dice.variables};
	}
	if(!!dice)
		setState({...state, dice, default: () => dice.calc()});
	let fillDefault = e => {
		let nState = {...state, default: e.target.value};
		nState = fillCalculatedFnForHeader(nState);
		setState(nState);
	}
	let changeName = (e) => {
		setState({...state, prevName: (state.prevName ?? state.name), name:e.currentTarget.value});
	}
	return (
		<>
		<h2>Edit - [{state.name}]</h2>
		<input type="text" value={state.name} onChange={changeName} />
		<div><label>Override propegates to next levels</label><input type="checkbox" value={state.fill_lower} onChange={e => {setState({...state, fill_lower: e.target.checked})}}/></div>
		<div><label>Default value</label><input type="text" value={state.default} onChange={fillDefault}/></div>
		<div className='button_group'>
			<button disabled={state.value==''} className='secondary' onMouseDown={() => {toggle();}}>Set</button>
			<button className='warn' onMouseDown={() => toggle({...state, deleteMe: true})}>Delete</button>
		</div>
		</>
	);
}

function damageOverLevels(item, character, monsters_stats) {
	let toHitDice = new Dice(!item.toHit ? '1d20' : item.toHit);
	let damageDice = new Dice(item.damageText);
	let line_chart_data = Object.keys(character).map(level => {
		let targetCR = character[level][crHeaderName].value;
		let monsterBins = monsters_stats.find(x => x.cr === targetCR);
		if(!monsterBins)
			return {level: Number(level), expectedDamage: 0};
		Object.keys(character[level]).forEach(k => {
			let value = character[level][k];
			value = value?.value ?? value;
			value = parseFloat(value) ?? value;
			if(['con', 'str', 'dex', 'wis', 'int', 'cha'].indexOf(k) !== -1)
				value = statToAbilityBonus(value)
			toHitDice.variables[k] = value;
		});
		damageDice.variables = toHitDice.variables;
		let hitChance = 0;
		let sumMonsters = monsterBins[item.targetStat].reduce((a, s) => a + s.c, 0);
		let dc = character[level].DC.value;
		if(item.targetStat == 'ac')
			hitChance = monsterBins.ac.reduce((acc, h) => acc + (h.c*clamp(0.05,toHitDice.chance_meet_or_beat(h.v),0.95)), 0) / sumMonsters;
		else
			hitChance = monsterBins[item.targetStat].reduce((acc, h) => acc + (h.c*clamp(0.0, 1-toHitDice.chance_meet_or_beat(dc - statToAbilityBonus(h.v)), 1.0)), 0) / sumMonsters;
		let diceHisto = damageDice.histogram;
		let diceHistoCount = diceHisto?.reduce((acc, c) => acc + c.count, 0) ?? 0;
		let avgDamage = 0;
		if (diceHistoCount > 0) {
			avgDamage = (diceHisto?.reduce((acc, c) => acc + (c.bin * c.count), 0) / diceHistoCount) ?? 0;
		}
		return {level: Number(level), toHit: hitChance, rawDamage: avgDamage, expectedDamage: hitChance * avgDamage}
	});
	line_chart_data.sort((a, b) => a.level - b.level);
	return line_chart_data;
}

function useDamageModalContent(state) {
	const { data: monsters_stats, isLoading, error } = useQuery({queryKey: ["monsters_stats"], queryFn: fetchData, staleTime: 10 * 1000});
	if(state === undefined || isLoading || error) return (<div>no state.</div>);
	let line_chart_data = damageOverLevels(state, state.character, monsters_stats);
	return (
		<>
		<h2>[{state.name}] / [character level]</h2>
		<LineChart height={300} width={600} data={line_chart_data}>
			<XAxis dataKey="level" />
			<YAxis yAxisId="left" width={40}/>
			<YAxis yAxisId="right" orientation='right'  domain={[0, 1]} tickFormatter={(tick) => {
			 return `${(tick*100)}%`;
			 }}/>
			<Tooltip contentStyle={{backgroundColor: 'black'}} />
			<Legend />
			<Line yAxisId="left" type="monotone" dataKey='expectedDamage' dot={false} stroke={'#f0e13a'}/>
			<Line yAxisId="left" type="monotone" dataKey='rawDamage' dot={false} stroke={'#b30e02'}/>
			<Line yAxisId="right" type="monotone" dataKey='toHit' dot={false} stroke={'#4cb302'}/>
		</LineChart>
		</>
	);
}

function useDamageEditModalContent(state, setState, toggle) {
	if(!state)
		return (<span>bad state.</span>)
	return (
		<>
		<h2>{state.name === '' ? "-" : state.name}</h2>
		<div>
			<label>Name:</label>
			<input type="text" value={state.name} onChange={e => setState({...state, name: e.target.value})}/>
		</div>
		<div>
			<label>Hit type: </label>
			<select value={state.targetStat} onChange={e => {setState({...state, targetStat: e.target.value.toLowerCase()})}}>
				{['ac', 'con', 'str', 'dex', 'wis', 'int', 'cha'].map(s => (				
				<option key={s} value={s}>{s}</option>
				))}
			</select>
		</div>
		{ state.targetStat === 'ac' &&
			<div>
				<label>To Hit:</label>
				<input type="text" value={state.toHit ?? ''} onChange={e => setState({...state, toHit: e.target.value})}/>
			</div>
		}
		<div>
			<label>Damage Dice:</label>
			<input type="text" value={state.damageText} onChange={e => setState({...state, damageText: e.target.value})}/>
		</div>
		<div className='button_group'>
			<button className='secondary' onMouseDown={() => {toggle();}}>Save</button>
		</div>
		</>
	);
}

const defaultDamageOptions = [
	{name: 'Dagger', aoe: false, targetStat: 'ac', damageText: '(1d4 + 5) * {atks}', toHit: '1d20 + {dex} + {prof}'},
	{name: 'Fire ball', aoe: true, targetStat: 'dex', damageText: '8d6'},
	{name: 'Cone of Cold', aoe: true, targetStat: 'con', damageText: '8d8'},
];

function CharacterPage() {
	let [_, edition] = useInput({ type: 'list', label: 'edition:', initial_state: '5e', list: [{value:'5e', text:'5e'}]});
	let [max_level, max_level_element] = useInput({ type: 'number', label: 'Max level:', initial_state: 20});
	let [crit_on, crit_on_element] = useInput({ type: 'number', label: 'Crit on:', initial_state: 20});
	let [row_headers, setHeaders] = useLocalStorage("character_headers", fiveEStats);
	let [character_state, set_character] = useLocalStorage("character_data", {});
	let [cellEditDialog, toggleCellModal] = useModal(EditCellModalContent, cellSave => {
		let next_set = JSON.parse(JSON.stringify(character_state));
		next_set[cellSave.level][cellSave.stat] = { value: cellSave.value, user_set: true };
		set_character(next_set);
	});
	let [headerEditDialog, toggleHeaderModal] = useModal(editHeaderModalContent, header => {
		let hi = row_headers.findIndex(r => r.name == (header.prevName ?? header.name));
		delete header.prevName;
		let rows = row_headers;
		if (header.deleteMe === true) {
			console.log('delete me?');
			rows = row_headers.filter((_, i) => i != hi);
		}
		else {
			rows = row_headers.slice(0);
			rows[hi] = header;
		}
		setHeaders(rows);
	});
	let [damageOptions, setDamageOptions] = useLocalStorage("characterDamageOptions", defaultDamageOptions);
	let [damageModalDialog, toggleDamageModal] = useModal(useDamageModalContent);
	let [damageEditModalDialog, toggleDamageEditModal] = useModal(useDamageEditModalContent, onSave => {
		let next= JSON.parse(JSON.stringify(damageOptions));
		let i = onSave.i;
		delete onSave.i;
		next[i] = onSave;
		setDamageOptions(next);
	});
	row_headers = row_headers.map(x => fillCalculatedFnForHeader(x));

	let addNewDamageOption = () => {
		setDamageOptions([...damageOptions, {name: `untitled #${damageOptions.length}`, aoe: false, targetStat: 'ac', damageText: '1d6'}]);
	};

	let character = fillCharacterLevelStats(character_state, row_headers, max_level);
	let addHeader = () => {
		let nextH = [...row_headers, {name: 'untitled', default: '0', readonly: false, fill_lower: true}];
		nextH = nextH.map(h => fillCalculatedFnForHeader(h));
		setHeaders(nextH);
	};
	return (
	<>
	{cellEditDialog}{headerEditDialog}{damageModalDialog}{damageEditModalDialog}
	<div>
		{edition}
		{max_level_element}
		<div>
			<button className='primary' onMouseDown={() => addHeader()}>+</button>
			<button className='primary' onMouseDown={() => {setHeaders(fiveEStats);set_character({});setDamageOptions(defaultDamageOptions);}}>Reset character</button>
		</div>

	</div>
	<table>
		<thead>
			<tr><th scope="col">Lvl</th>{row_headers.map(stat => (<th key={stat.name} scope="col" className="cell-edit" onMouseDown={() => {toggleHeaderModal(stat)}}>{stat.name}</th>))}</tr>
		</thead>
		<tbody>
			{Array(max_level).fill(0).map((_, level) => level + 1).map(level => (
				<tr key={level}>
					<td>{level}</td>
					{row_headers.map(stat => (
						<td key={stat.name} scope="col" className={stat.readonly ? '' : 'cell-edit'} onMouseDown={() => {if(!stat.readonly){
							toggleCellModal({value: character[level][stat.name].value, level: level, stat: stat.name});
						}}}
						>
							<span className={character[level][stat.name].user_set === true ? 'user_set' : 'user_not_set'}>
								{character[level][stat.name].value}
							</span>
						</td>
					))}
				</tr>
			))}
		</tbody>
	</table>
	{crit_on_element}
	<h3>Damage Options <button className='primary' onMouseDown={addNewDamageOption}>+</button></h3>
	<table>
		<thead>
			<tr>
				<th scope="col">Name</th>
				<th scope="col">Damage</th>
				<th scope="col">Targets</th>
				<th scope="col">Aoe</th>
				<th scope="col"></th>
			</tr>
		</thead>
		<tbody>
			{damageOptions.map((dmgOpt, i) => (
			<tr key={i}>
				<td>{dmgOpt.name}</td>
				<td>{dmgOpt.damageText}</td>
				<td>{dmgOpt.targetStat}</td>
				<td>{dmgOpt.aoe ? 'aoe' : 'single'}</td>
				<td><button onMouseDown={() => toggleDamageEditModal({...dmgOpt, character, i})}>edit</button>
				<button onMouseDown={() => toggleDamageModal({...dmgOpt, character})}>view</button></td>
			</tr>
			))}
		</tbody>
	</table>
	</>
	);
}

export default CharacterPage;

