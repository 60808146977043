import '../styles/SpellDetails.sass';
import { useQuery } from "@tanstack/react-query";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

async function fetchSpell(id) {
	let data = await fetch(`${process.env.REACT_APP_API}/spell/${id}`)
	return data.json()
}

function SpellDetails() {
	const { id } = useParams();
	const { data, isLoading, error } = useQuery({queryKey: ["allspells", id], queryFn: () => fetchSpell(id), staleTime: 10 * 1000});

	if(isLoading) return (<div>Loading</div>);
	if(error) return (<div>Error: {error.message}</div>);

	return (
	<>
		<button className="primary">Save</button>
		<Link to="/spell" className="accent button">Back</Link>
		<button className="warn">Delete</button>
		<h1>{data.name}</h1>
		<div dangerouslySetInnerHTML={{ __html: data.description }} />
		{data.class?.map((s, i) => <span key={i} className={"pill-" + s}> {s} </span>)}
	</>
	);
}

export default SpellDetails;
